<template>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

      <div class="h-7 text-center">
        <div v-if="authToken" class="inline-flex text-base leading-6 font-medium rounded-md text-green-600 cursor-not-allowed">
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Verifying...
        </div>
      </div>

      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <h2
          class="text-gray-700 font-medium text-center mb-6"
        >Log in to Kairo</h2>

        <div v-if="msgError" class="rounded-md bg-red-50 p-4 mb-6">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: x-circle -->
              <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                There was a problem!
              </h3>
              <div class="mt-2 text-sm text-red-700">
                {{msgError}}
              </div>
            </div>
          </div>
        </div>
        <div v-if="msgSuccess" class="rounded-md bg-green-50 p-4 mb-6">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: x-circle -->
              <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-green-800">
                Please check your email!
              </h3>
              <div class="mt-2 text-sm text-green-700">
                {{msgSuccess}}
              </div>
            </div>
          </div>
        </div>

        <div>
          <Form
            @submit="login()"
            class="space-y-6"
          >
            <div>
              <label for="email_login" class="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div class="mt-1">
                <Field
                  name="email"
                  :rules="validateEmail"
                  id="email_login"
                  v-model="crendentials.email"
                  type="email"
                  placeholder="hey@email.com"
                  autocomplete="email"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <ErrorMessage name="email" class="mt-2 text-sm text-red-600" />

              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div class="mt-1">
                <Field
                  v-model="crendentials.password"
                  :type="passwordType"
                  id="password"
                  name="password"
                  :rules="isRequired"
                  autocomplete="current-password"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                <ErrorMessage name="password" class="mt-2 text-sm text-red-600" />
              </div>
            </div>

            <div>
              <button
                :disabled="lockButtons"
                class="disabled:opacity-50 w-full border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50"
                style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
              >
                Sign in
              </button>
            </div>

            <div class="relative">
            </div>
          </Form>
        </div>

        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-white text-gray-500">
            </span>
            </div>
          </div>
          <div>
            <div class="relative flex justify-center text-sm mt-4">
              <span class="px-2 bg-white text-gray-500">
                <router-link
                  to="/recover"
                  class="font-medium text-indigo-600 hover:text-indigo-500"
                >Can't log in?
                </router-link>
                •
                <router-link
                  to="/signup"
                  class="font-medium text-indigo-600 hover:text-indigo-500"
                >Sign up for an account
                </router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class="flex justify-center space-x-6 md:order-2">
        <a href="https://twitter.com/kairohq" target="_blank" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Twitter</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
      </div>
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-left text-base text-gray-400">
          &copy; 2024 Sotak, Ltd. All rights reserved. Contact: support@getkairo.com<br />
          <router-link to="terms">Terms of Service</router-link> | <router-link to="privacy-policy">Privacy Policy</router-link> | <router-link to="acceptable-use-policy">Acceptable Use Policy</router-link>
        </p>
      </div>
    </div>
  </footer>

  <div v-if="isDevEnvironment" class="mt-6">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <SetNetlifyURL />
    </div>
  </div>

</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import SetNetlifyURL from "../components/SetNetlifyURL.vue";
  import { Field, Form, ErrorMessage } from 'vee-validate';

  export default {
    components: {
      SetNetlifyURL,
      Field,
      Form,
      ErrorMessage
    },
    data() {
      return {
        crendentials: {
          password: "",
          email: ""
        },
        hidePassword: true,
        toggleModal: false,
        lockButtons: false,
        msgError: null,
        msgSuccess: null,
        confirmationToken: null
      };
    },
    computed: {
      ...mapGetters("app", ["isDevEnvironment"]),
      ...mapGetters("auth", ["authToken"]),
      passwordType() {
        return this.hidePassword ? "password" : "text";
      },
      passwordIcon() {
        return this.hidePassword ? "eye-open" : "eye-closed";
      },
    },
    methods: {
      ...mapActions("auth", [
        "attemptLogin"
      ]),
      login() {
        this.msgError = '';
        this.msgSuccess = '';
        this.lockButtons = true;
        this.attemptLogin({ ...this.crendentials })
            .then((user) => {
              if (user.id) {
                if (typeof window.InlineManual !== 'undefined') {
                  window.InlineManual("shutdown");
                  window.InlineManual("boot", {
                    uid: user.id
                  });
                }
              }

              this.lockButtons = false;
              this.$router.push(this.$route.query.redirect || "tracker");
            })
            .catch(error => {
              let msgError = `${error}`;
              if (msgError.indexOf("invalid_grant")) {
                msgError = 'There isn\'t an account for this email, or password is invalid.';
              }
              this.msgError = msgError;
              this.lockButtons = false;
            });
      },
      isRequired(value) {
        return value ? true : 'This field is required';
      },
      validateEmail(value) {
        if (!value) {
          return 'This field is required';
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
          return 'This field must be a valid email';
        }
        return true;
      },
    },
    mounted() {
      try {
        this.confirmationToken = decodeURIComponent(document.location.hash).split(
            "confirmation_token="
        )[1];
      } catch {
        this.confirmationToken = null;
      }
    }
  };
</script>
