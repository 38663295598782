<template>
  <div class="shadow bg-red-50 p-4 border border-red-900">
    <span class="text-md font-bold text-red-900">DEV Site</span><br />
    <span v-if="siteURL" id="url-hint" class="text-sm font-medium text-gray-700">Set URL: https://{{ siteURL }} </span><br />
    <div class="flex mt-2">
      <div class="inline-flex ">
        <div class="flex rounded-md shadow-sm">
          <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            http://
          </span>
          <input type="text" name="company-website" id="company-website"
             v-model="netlifyURL"
             placeholder="YOUR-NETLIFY-SITE.netlify.com"
             class="inline-flex min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
          >
        </div>
      </div>

      <button type="button" @click="setURL()" class="ml-2 inline-flex items-center px-3 py-0 border border-transparent text-sm leading-3 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Set URL
      </button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "SetNetlifyURL",
    data() {
      return {
        netlifyURL: ""
      };
    },
    computed: {
      ...mapGetters("app", ["isDevEnvironment", "siteURL"])
    },
    methods: {
      setURL() {
        this.$store.commit("app/SET_SITE_URL", this.netlifyURL);
      }
    }
  };
</script>
